
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Circular Std';
  src: url('../public/CircularStd-Book.otf') format('otf'),
       url('../public/CircularStd-Medium.otf') format('otf');
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family:'Helvetica', 'Arial', 'sans-serif';
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-size: 10px;
}

@media (min-width: 0px) and (max-width : 600px) {
  .instructions {
    font-size: 1.5rem;
  }
}

@media (min-width: 600px){
  .instructions {
    font-size: 1.25rem;
  }
}

.calories-and-steps {
}
.calories {
}
@media (min-width: 0px) and (max-width : 343px) {
  .calories-and-steps {
    flex-wrap: wrap;
  }
  .calories {
    margin-right: 0px !important;
    margin-bottom: 2rem;
  }
}
@media (min-width : 250px) and (max-width : 400px) {
  html, body, #root {
    font-size: 10px;
  }
}

@media (min-width : 500px) and (max-width : 650px) {
  html, body, #root {
    font-size: 12px;
  }
}

@media (min-width : 650px) and (max-width : 768px) {
  html, body, #root {
    font-size: 16px;
  }
}


@media (min-width : 768px) and (max-width : 1024px) {
  html, body, #root {
    font-size: 18px;
  }
  .pain-type-btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width : 1025px) {
  html, body, #root {
    font-size: 24px;
  }
  .camera-container {
    max-width: 800px;
    margin: 0 auto;
  }
  .reverse-button {
    display: none;
  }
  .react-slider {
    margin: 0 auto;
  }
  .pain-type-btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.hidden{
  display: none;
}

.layout {
  background-color: #FBFBFB;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
}

#mcvideo {
  object-fit: fill;
}

.removeVisitedLinkColor a:link {
  color: '#FFFFFF';
}

.video_box{
  position: relative;
  height: 100%;
  width: 100%;
}

.video_overlays {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 15px;
  left: 0;
  margin: 0px;
}

.video_picture_square {
  width: 90%;
  height: 70%;
  border: white 1px solid;
  padding-top: 10%;
  margin: 0px auto;
}

/* slider information */

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}
/* track data */
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-radius: 1.3px;
  border: 0.2px solid #207bcc;
}
/* draggable thumb */
input[type=range]::-webkit-slider-thumb {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: red;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -16px;
}

input[class=painSlider0]::-webkit-slider-thumb {
  background: #64b346;
}

input[class=painSlider1]::-webkit-slider-thumb {
  background: #64b346;
}

input[class=painSlider2]::-webkit-slider-thumb {
  background: #64b346;
}

input[class=painSlider3]::-webkit-slider-thumb {
  background: #ffd479;
}

input[class=painSlider4]::-webkit-slider-thumb {
  background: #ffd479;
}

input[class=painSlider5]::-webkit-slider-thumb {
  background: #ffd479;
}

input[class=painSlider6]::-webkit-slider-thumb {
  background: #f12800;
}

input[class=painSlider7]::-webkit-slider-thumb {
  background: #f12800;
}

input[class=painSlider8]::-webkit-slider-thumb {
  background: #f12800;
}

input[class=painSlider9]::-webkit-slider-thumb {
  background: #f12800;
}

input[class=painSlider10]::-webkit-slider-thumb {
  background: #FF3D00;
}

/* **************React Slider***************
/**
* Rangeslider
*/
.rangeslider {
  /* margin: 20px 10px; */
  align-items: center;
  position: relative;
  background: #e4eff9;
  -ms-touch-action: none;
  touch-action: none;
  width: 90%;
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}
.rangeslider .rangeslider__handle {
  background: #fff;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  border-radius:100%;
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
/**
* Rangeslider - Horizontal slider
*/

.rangeslider-horizontal {
  height: 0.25rem;
  border-radius: 10px;
  margin-top: 2.81rem;
  margin: 0 auto;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #18b79b;
  border-radius: 10px;
  left: 10px;
}
.rangeslider-horizontal .rangeslider__handle {
  background-color: #18b79b;
  border-radius: 50%;
  height: 4.81rem;
  width: 4.83rem;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1.25rem;
  word-spacing: 100px;
}

/* Tooltip */
.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  /* background-color: rgba(0, 0 ,0 , 0.8); */
  background-color: rgba(24, 183 ,155 , 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  /* border-color: #18B79B transparent transparent transparent; */
  border-color:  rgba(24, 183 ,155 , 0.8) transparent transparent transparent;
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  /* border-top: 8px solid rgba(24, 183, 155 0.8); */
  border-top: 8px solid rgba(24, 183 ,155 , 0.8);
  left: 50%;
  bottom: -12.5px;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider__handle-tooltip:focus, .rangeslider__handle:focus {
    outline-width: 0;
}

/*
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
  list-style-type: none;
  background-color: rgb(125, 31, 187);
  position: absolute;
  margin-top: .75rem;
  left: -1rem;
  color: #ffffff;
}

.rangeslider__label-item {
  top: 1.69rem;
  word-wrap: break-word;
  position: absolute;
  font-size: 1.25rem;
  cursor: pointer;
  word-spacing: 100px;
}
/* **************React Pain Intense Slider***************
/**
* Rangeslider Pain Intense
*/
.custom-rangeslider {
  display: flex;
  margin: 40vw 6vw; 
  align-items: center;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
}

.custom-rangeslider .rangeslider, .rangeslider .rangeslider__fill{
  display: block;
  box-shadow: unset !important;
}

.custom-rangeslider
.rangeslider .rangeslider__handle {
  background: #fff;
  cursor: pointer;
  border: .2vw solid black;    
  display: inline-block;
  border-radius:100%;
}

.custom-rangeslider
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

/**
* Rangeslider - Horizontal slider
**/
.custom-rangeslider
.rangeslider-horizontal {
  position: relative; 
  background-color: #3EBCF9;
  height: 0.4rem;
  border-radius: 10vw;
  width: 100%;
}

.custom-rangeslider
.rangeslider-horizontal .rangeslider__fill {
  background-color: #3EBCF9;
  height: 100%;
  border-radius: 10vw;
  left: 10vw;
}

.custom-rangeslider
.rangeslider-horizontal .rangeslider__handle {
  background-color: white;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: black;
  font-size: 1.25rem;
  word-spacing: 100vw;
}
.custom-rangeslider
.rangeslider-horizontal .rangeslider__handle-label {
font-size: 5vw;
}
.marks{
  width: 90vw;
}
/*
marks on the pain intense slider
*/
#mark1{
  position: absolute;
  background-color: #3EBCF9;
  height: 7vw;
  width: 1vw;
  right: 87vw;
}
#mark2{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  right: 74vw;
}
#mark3{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  right: 66vw;
}
#mark4{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  right: 59vw;
}
#mark5{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  right: 51vw;
}
#mark6{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  left: 44vw;
}
#mark7{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  left: 51vw;
}
#mark8{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  left: 58vw;
}
#mark9{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  left: 67vw;
}
#mark10{
  position: absolute;
  background-color: #3EBCF9;
  height: 4vw;
  width: .8vw;
  left: 75vw;
}
#mark11{
  position: absolute;
  background-color: #3EBCF9;
  height: 7vw;
  width: 1vw;
  left: 87vw;
}
/* end of react-slider */
